<template>
  <div class="nav-top">
    <a-button
      ref="btnRef"
      style="margin-top: 5px; padding-bottom: 3px"
      @click="photoClick"
    >
      图片
    </a-button>
    <a-button
      style="margin-top: 5px; padding-bottom: 3px; margin-left: 5px"
      @click="videoClick"
    >
      视频
    </a-button>
    <!--搜索框1 选择年份-->
    <a-select
      v-show="isPhoto"
      style="width: 120px; margin-top: 5px; margin-left: 20px"
      placeholder="请选择年份"
      v-model:value="theYear"
      @change="getMonthData"
    >
      <a-select-option
        v-for="(item, index) in yearList"
        :key="index"
        :value="item"
        >{{ item }}年</a-select-option
      >
    </a-select>
    <!--搜索框2 选择月份-->
    <a-select
      v-show="isPhoto"
      style="width: 120px; margin-top: 5px"
      placeholder="请选择月份"
      v-model:value="theMonth"
      @change="getDayData"
    >
      <a-select-option
        v-for="(item, index) in monthArr"
        :key="index"
        :value="item.month"
        >{{ item.label }}</a-select-option
      >
    </a-select>
    <!--搜索框3 选择具体日期-->
    <a-select
      v-show="isPhoto"
      style="width: 170px; margin-top: 5px"
      placeholder="请选择具体的日期"
      v-model:value="theTime"
    >
      <a-select-option
        v-for="(item, index) in timeArr"
        :key="index"
        :value="item.date"
        >{{ item.label }}</a-select-option
      >
    </a-select>
    <a-button
      type="primary"
      style="margin-left: 20px"
      @click="getUpTableData"
      v-show="isPhoto"
    >
      <template #icon><SearchOutlined /></template>搜索
    </a-button>
    <a-button
      type="danger"
      v-show="isPhoto"
      :disabled="this.theTime === null || this.data1.length === 0"
      style="float: right; margin-right: 20px; margin-top: 5px"
      @click="deletePagePhoto"
    >
      批量删除图片
    </a-button>
    <!-- <a-button type="danger" v-show="!isPhoto" style="float:right; margin-right: 20px; margin-top:5px" @click="deletePageVedio">
        删除此页视频
      </a-button> -->
  </div>
  <a-modal :visible="loadingVisible" :footer="null" :closable="false">
    <a-spin>
      <a-alert message="正在上传" description="请不要关闭窗口"></a-alert>
    </a-spin>
  </a-modal>
  <a-table
    v-show="isPhoto"
    :columns="columns1"
    :data-source="data1"
    :loading="loading1"
    :pagination="pagination1"
    :row-key="(record) => record.id"
    @change="getCUpTableData"
  >
    <template #mypicture="{ text }">
      <img
        :src="text"
        alt="图片"
        style="cursor: pointer"
        width="60"
        @dblclick="showPhoto(text)"
      />
    </template>
    <template #regionId="{ text }">
      <span v-if="text === 0">区外</span>
      <span v-if="text === 1">区块1</span>
      <span v-if="text === 2">区块2</span>
      <span v-if="text === 3">区块3</span>
      <span v-if="text === 4">区块4</span>
      <span v-if="text === 5">区块5</span>
      <span v-if="text === 6">区块6</span>
      <span v-if="text === 7">区块7</span>
      <span v-if="text === 8">区块8</span>
    </template>
    <template #action1="{ text }">
      <a-button type="danger" class="delete" @click="showWaringPhoto(text)"
        >删除图片</a-button
      >
    </template>
  </a-table>

  <a-table
    v-show="!isPhoto"
    :columns="columns2"
    :data-source="data2"
    :loading="loading2"
    :pagination="pagination2"
    :row-key="(record) => record.id"
    @change="getVUpTableData"
    :customRow="rowClick"
  >
    <template #action="{ text }">
      <img :src="text" alt="缩略图" height="40" />
    </template>
    <template #action2="{ text }">
      <a-button type="danger" class="delete" @click="showWaringVideo(text)"
        >删除视频</a-button
      >
      <a-button type="primary" class="delete" @click="showVideo(text)"
        >点击预览</a-button
      >
      <a-button class="delete" @click="changeTakeTime(text)">修改时间</a-button>
    </template>
  </a-table>

  <!-- 修改拍摄时间的模态框 -->
  <a-modal
    v-model:visible="changeVisible"
    title="时间修改"
    :center="true"
    @ok="handleChangeTime"
    @cancel="handleCancel"
    cancelText="取消"
    okText="确认"
  >
    <div>
      <span>当前拍摄时间：</span>
      <a-input
        v-model:value="currentTime"
        style="width: 200px; margin-left: 30px; margin-bottom: 15px"
        :disabled="true"
      />
    </div>
    <div>
      <span>修改后拍摄时间：</span>
      <a-date-picker
        placeholder="请选择拍摄时间"
        :value="changeTime"
        @change="onChange"
        style="margin-left: 15px"
        :locale="zhCN"
        change
      />
    </div>
  </a-modal>
  <a-modal
    title="请核对数据格式"
    :visible="validVisible"
    :footer="null"
    @cancel="validCancle"
  >
    <div style="color: red">拍摄时间不可为空！</div>
  </a-modal>

  <a-modal
    v-model:visible="visible"
    title="视频播放"
    :footer="null"
    width="800"
    :centered="true"
  >
    <video :src="Vurl" muted controls width="800" height="500"></video>
  </a-modal>
  <a-modal
    v-model:visible="visible1"
    title="图片详情"
    :footer="null"
    width="800"
    :centered="true"
  >
    <img :src="PUrl" width="800" height="500" />
  </a-modal>
</template>
<script >
import { createVNode, defineComponent } from "vue";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import { getAnyDate } from "@/tools/datefmt";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
const columns1 = [
  {
    title: "图片",
    dataIndex: "photoUrl",
    key: "photoUrl",
    align: "center",
    slots: { customRender: "mypicture" },
  },
  {
    title: "图片名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "上传者",
    dataIndex: "operator.userName",
    key: "operator.userName",
    align: "center",
  },
  {
    title: "拍摄时间",
    dataIndex: "photoTime",
    key: "photoTime",
    align: "center",
  },
  {
    title: "上传时间",
    dataIndex: "updateTime",
    key: "updateTime",
    align: "center",
  },
  {
    title: "区域",
    dataIndex: "regionId",
    key: "regionId",
    align: "center",
    slots: { customRender: "regionId" },
  },
  {
    title: "操作",
    slots: { customRender: "action1" },
    // key: "action1",
    align: "center",
  },
];
const columns2 = [
  {
    title: "视频封面",
    dataIndex: "videoCoverUrl",
    key: "videoCoverUrl",
    align: "center",
    slots: { customRender: "action" },
  },
  {
    title: "视频名称",
    dataIndex: "fileName",
    key: "fileName",
    align: "center",
  },
  {
    title: "上传者",
    dataIndex: "operator.userName",
    key: "operator.userName",
    align: "center",
  },
  {
    title: "视频拍摄时间",
    dataIndex: "takeTime",
    key: "takeTime",
    align: "center",
    slots: {
      customRender: "takeTime",
    },
  },
  {
    title: "操作",
    slots: { customRender: "action2" },
    key: "action2",
    align: "center",
  },
];
const data1 = [];
const data2 = [];
export default defineComponent({
  name: "Personalmanage",
  components: {
    SearchOutlined,
  },
  data() {
    return {
      vedioChangeText: {},
      //控制未选时间时不可以进行批量的删除
      deleteControl: true,
      // 用来控制级联时间以及当前的时间
      //选择的年份
      theYear: null,
      //选择的月份
      theMonth: null,
      monthArr: [],
      //日期的选择和搜索，文件导出
      theTime: null,
      timeArr: [],
      yearList: [],
      // 用来控制显示的是视频列表还是图片列表
      isPhoto: true,
      Vurl: "",
      PUrl: "",
      //当前视频的时间
      currentTime: "",
      changeTime: "",
      validVisible: false,
      changeVisible: false,
      visible: false,
      visible1: false,
      loadingVisible: false,
      activeKey: "1",
      columns1: columns1,
      columns2: columns2,
      data1: data1,
      data2: data2,
      loading1: false,
      loading2: false,
      pagination1: {
        pageSize: 7, //一页是8条数据
        total: 0,
        current: 1,
      },
      pagination2: {
        pageSize: 7, //一页是8条数据
        total: 0,
        current: 1,
      },
    };
  },
  methods: {
    //修改上传视频的拍摄时间
    changeTakeTime(text) {
      this.vedioChangeText = text;
      this.changeVisible = true;
      this.currentTime = text.takeTime;
      this.changeTime = text.takeTime;
    },
    //关闭模态框
    handleCancel() {
      this.changeVisible = false;
      this.changeTime = "";
    },
    //点击确定关闭模态框并真正的发送请求
    handleChangeTime() {
      if (this.changeTime === "") {
        this.validVisible = true;
        return false;
      } else {
        this.$axios({
          url: "http://39.100.158.75:8080/photo/updateTakeTime",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: this.$storage.get("userinfo").data.token,
          },
          method: "POST",
          params: {
            id: this.vedioChangeText.id,
            takeTime: this.changeTime,
          },
        }).then((res) => {
          console.log(res);
          if (res.data.success) {
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        });
      }
      this.getVTableData();
      this.changeVisible = false;
      this.changeTime = "";
    },
    //关闭校验模态框
    validCancle() {
      this.validVisible = false;
    },
    onChange(value, dateString) {
      console.log(value);
      this.changeTime = dateString;
    },
    //根据年份查月份
    getMonthData() {
      this.monthArr = [];
      this.theMonth = null;
      this.timeArr = [];
      this.theTime = null;
      let url = "http://39.100.158.75:8080/photo/findAllPhotoTimeYear";
      this.$axios({
        url,
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          year: this.theYear,
        },
      }).then((res) => {
        this.monthArr = [];
        for (let el of res.data.data) {
          el.label = el.label + "月";
          this.monthArr.push(el);
        }
      });
    },
    //根据年份和月份查日期
    getDayData() {
      this.timeArr = [];
      this.theTime = null;
      let url = "http://39.100.158.75:8080/photo/findAllPhotoTimeMonth";
      this.$axios({
        url,
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          year: this.theYear,
          month: this.theMonth,
        },
      }).then((res) => {
        this.timeArr = res.data.data;
      });
    },
    // 批量删除图片
    deletePagePhoto() {
      let that = this;
      Modal.confirm({
        title: "你确定要批量删除图片吗？",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          "div",
          { style: "color: red;" },
          "本次操作将会永久地删除当前所查图片！"
        ),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.loadingVisible = true;
          that
            .$axios({
              url: "http://39.100.158.75:8080/photo/deletePhotoListByTime",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: that.$storage.get("userinfo").data.token,
              },
              params: {
                photoTime: that.theTime,
              },
            })
            .then((res) => {
              that.loadingVisible = false;
              that.getMonthData();
              that.getDayData();
              if (res.data.success) {
                message.success(res.data.message);
              } else {
                message.error(res.data.message);
              }
              that.pagination1.current = 1;
              that.getUpTableData();
            });
        },
        onCancel() {},
      });
    },
    photoClick() {
      this.isPhoto = true;
      this.pagination1.current = 1;
      // this.getUpTableData();
    },
    videoClick() {
      this.isPhoto = false;
      this.pagination2.current = 1;
      this.getVTableData();
    },
    showPhoto(text) {
      this.PUrl = text;
      this.visible1 = true;
    },
    showVideo(text) {
      this.Vurl = text.videoUrl;
      this.visible = true;
    },
    showWaringPhoto(data) {
      console.log(data);
      let that = this;
      Modal.confirm({
        title: "你确定要删除这张图片吗？",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          "div",
          { style: "color: red;" },
          "本次操作将会永久地删除这张图片！"
        ),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.deletePic(data);
        },
        onCancel() {},
      });
    },
    showWaringVideo(data) {
      console.log(data);
      let that = this;
      Modal.confirm({
        title: "你确定要删除这个视频吗？",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          "div",
          { style: "color: red;" },
          "本次操作将会永久地删除这个视频！"
        ),
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.deleteV(data);
        },
        onCancel() {},
      });
    },
    getCUpTableData(event) {
      this.pagination1.current = event.current;
      this.getUpTableData();
    },
    getVUpTableData(event) {
      this.pagination2.current = event.current;
      this.getVTableData();
    },
    getUpTableData() {
      this.loading1 = true;
      let api = "http://39.100.158.75:8080/photo/findPhotoListByPage";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination1.current,
          limit: this.pagination1.pageSize,
          startTime: this.theTime === null ? getAnyDate(0) : this.theTime,
          endTime: this.theTime === null ? getAnyDate(0) : this.theTime,
          type: "0",
          isGps: "0",
        },
      })
        .then((response) => {
          //   console.log(response);
          if (response.data.data == null) {
            message.info(response.data.message);
            this.data1 = [];
            this.pagination1.total = 0;
            this.loading1 = false;
          } else {
            this.data1 = response.data.data.list;
            this.pagination1.total = response.data.data.totalPageCount;
            this.loading1 = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVTableData() {
      this.loading2 = true;
      let api = "http://39.100.158.75:8080/photo/findVideoListByPage";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination2.current,
          limit: this.pagination2.pageSize,
        },
      })
        .then((response) => {
          //   console.log(response);
          if (response.data.data == null) {
            message.info(response.data.message);
            this.data2 = [];
            this.pagination2.total = 0;
            this.loading2 = false;
          } else {
            this.data2 = response.data.data.list;
            this.pagination2.total = response.data.data.totalPageCount;
            this.loading2 = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePic(data) {
      let api = "http://39.100.158.75:8080/photo/deleteOnePhotoByName";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          name: data.name,
        },
      })
        .then((response) => {
          if (response.data.success) {
            message.success("删除成功");
          } else {
            message.error(response.data.message);
          }
          //   console.log(response);

          let pagNum = this.pagination1.total / this.pagination1.pageSize;
          if (
            (this.pagination1.total - 1) % this.pagination1.pageSize === 0 &&
            this.pagination1.current > pagNum
          ) {
            this.pagination1.current =
              this.pagination1.current - 1 > 0
                ? this.pagination1.current - 1
                : 1;
          }
          this.getUpTableData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteV(data) {
      let api = "http://39.100.158.75:8080/photo/deleteOneVideoByName";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          name: data.name,
        },
      })
        .then((response) => {
          if (response.data.success) {
            message.success("删除成功");
          } else {
            message.error(response.data.message);
          }

          let pagNum = this.pagination2.total / this.pagination2.pageSize;
          if (
            (this.pagination2.total - 1) % this.pagination2.pageSize === 0 &&
            this.pagination2.current > pagNum
          ) {
            this.pagination2.current =
              this.pagination2.current - 1 > 0
                ? this.pagination2.current - 1
                : 1;
          }
          this.getVTableData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    for (let i = 2020; i < 2100; i++) {
      this.yearList.push(i);
    }
    // this.getUpTableData();
    this.getVTableData();
  },
});
</script>
<style lang="scss">
.nav-top {
  height: 45px;
  width: 100%;
}
.delete {
  margin-left: 10px;
}
// 时间编辑的样式
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
